<template>
    <div class="screen-news-detail">
        <header class="ql__client-header-common">
            <p class="ql__client-breadcrumbs">
                <span class="active" @click="$router.push('/')">{{ getTranslatedText('trang chủ') }}</span>
                <span> » </span>
                <span class="active" @click="$router.push('/news')"> {{ getTranslatedText('tin tức sự kiện') }} </span>
                <span> » </span>
                <span>{{ title }}</span>
            </p>
        </header>
        <h1 class="ql__client-page-title">{{ title }}</h1>
        <p class="screen-news-detail-description" v-html="description"></p>
    </div>
</template>
<script>
import { isObjEmpty } from '@/utils/utils'

import newsService from '@/services/news.service'
import languageMixins from '@/mixins/language/language-mixins'

export default {
    mixins: [languageMixins],
    data() {
        return {
            loading: false,
            title: '',
            description: '',
        }
    },
    created() {
        this.fetchdata()
    },
    methods: {
        async fetchdata() {
            try {
                let slug = this.$router.history.current.params.slug
                const resp = await newsService.detail(slug)
                if (resp.code === 200 && !isObjEmpty(resp.data)) {
                    this.title = resp.data[this.getTranslatedTextData('title')]
                    this.description = resp.data[this.getTranslatedTextData('description')]
                } else {
                    this.$router.push('/news')
                }
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/news/_detail.scss';
</style>
