import { RouteConfig } from 'vue-router'
import { metaRequireAuth } from './meta-constants'

import HomeCms from '@/views/cms/home/index.vue'

import AboutCms from '@/views/cms/about/index.vue'
import AboutUpdateCms from '@/views/cms/about/detail.vue'

import BannersCms from '@/views/cms/banner/index.vue'
import BannerDetailCms from '@/views/cms/banner/detail.vue'
import BannerCreateCms from '@/views/cms/banner/create.vue'

import ServivesCms from '@/views/cms/services/index.vue'
import ServivesDetailCms from '@/views/cms/services/detail.vue'
import ServivesCreateCms from '@/views/cms/services/create.vue'

import NewsCms from '@/views/cms/news/index.vue'
import NewsDetailCms from '@/views/cms/news/detail.vue'
import NewsCreateCms from '@/views/cms/news/create.vue'

import LocaleCms from '@/views/cms/locale/index.vue'
import LocaleDetailCms from '@/views/cms/locale/detail.vue'
import LocaleCreateCms from '@/views/cms/locale/create.vue'

import RegisterServicesCms from '@/views/cms/register-services/index.vue'
import RegisterServicesCreateCms from '@/views/cms/register-services/create.vue'
import RegisterServicesDetailCms from '@/views/cms/register-services/detail.vue'

const authenticatedRoutes: Array<RouteConfig> = [
    {
        path: '/cms',
        name: 'Cms Home',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: HomeCms,
    },
    {
        path: '/cms/about/update/:id',
        name: 'Cms about update',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: AboutUpdateCms,
    },
    {
        path: '/cms/about',
        name: 'Cms about',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: AboutCms,
    },
    {
        path: '/cms/servives',
        name: 'Cms Servives',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: ServivesCms,
    },
    {
        path: '/cms/servives/update/:slug',
        name: 'Cms Servives detail',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: ServivesDetailCms,
    },
    {
        path: '/cms/servives/create',
        name: 'Cms Servives create',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: ServivesCreateCms,
    },
    {
        path: '/cms/news',
        name: 'Cms news',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: NewsCms,
    },
    {
        path: '/cms/news/update/:slug',
        name: 'Cms news detail',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: NewsDetailCms,
    },
    {
        path: '/cms/news/create',
        name: 'Cms news create',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: NewsCreateCms,
    },
    {
        path: '/cms/locales',
        name: 'Cms locales',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: LocaleCms,
    },
    {
        path: '/cms/locales/update/:id',
        name: 'Cms locales detail',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: LocaleDetailCms,
    },
    {
        path: '/cms/locales/create',
        name: 'Cms locales create',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: LocaleCreateCms,
    },
    {
        path: '/cms/banners',
        name: 'Cms Banners',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: BannersCms,
    },
    {
        path: '/cms/banners/create',
        name: 'Cms banners create',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: BannerCreateCms,
    },
    {
        path: '/cms/banners/update/:id',
        name: 'Cms banners detail',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: BannerDetailCms,
    },
    {
        path: '/cms/register-services',
        name: 'Cms Register Services',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: RegisterServicesCms,
    },
    {
        path: '/cms/register-services/create',
        name: 'Cms Register Service create',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: RegisterServicesCreateCms,
    },
    {
        path: '/cms/register-Services/update/:id',
        name: 'Cms Register Service detail',
        meta: {
            isCms: true,
            ...metaRequireAuth,
        },
        component: RegisterServicesDetailCms,
    },
]

export default authenticatedRoutes
