<template>
    <header class="header" v-if="!this.isLoading">
        <v-app-bar-nav-icon v-if="$store.state.isScreenMobile" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <div class="header_logo">
            <img src="~ThemePath/images/logo.png" />
        </div>
        <div v-if="!$store.state.isScreenMobile" class="header-nav">
            <div class="header-action">
                <div class="header-action__language">
                    <img src="~ThemePath/images/lang/en.svg" @click="onChangeLocale('en')" />
                    <img src="~ThemePath/images/lang/vi.svg" @click="onChangeLocale('vi')" />
                </div>
            </div>
            <ul class="header-menu">
                <li class="header-menu-item" :class="{ active: active === v.url }" v-for="(v, i) in listMenu" :key="i">
                    <span class="header-menu-item-text" @click="() => redirectUrl(v.url)">{{ getTranslatedText(v.title) }}</span>
                    <ul class="header-menu-item__sub-menu" v-if="v.subMenu && v.subMenu.length > 0">
                        <li class="header-menu-item__sub-menu-item" :class="{ active: active === v.id }" v-for="(sub, index) in v.subMenu" :key="index" @click="onClickDetail(sub.slug)">
                            <span>{{ sub[`${getTranslatedTextData('title')}`] }}</span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <template v-else>
            <v-navigation-drawer class="header-menu-toggle" v-model="drawer" fixed temporary>
                <v-list dense>
                    <i class="fa-solid fa-xmark button-close" @click.stop="drawer = !drawer"></i>
                    <v-list-item :class="{ active: active === v.url }" v-for="(v, i) in listMenu" :key="i">
                        <v-list-item-content @click="() => redirectUrl(v.url)">
                            <v-list-item-title>{{ getTranslatedText(v.title) }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
            <div class="header-action">
                <div class="header-action__language">
                    <img src="~ThemePath/images/lang/en.svg" />
                    <img src="~ThemePath/images/lang/vi.svg" />
                </div>
            </div>
        </template>
    </header>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import servicesService from '@/services/service.service'
import { isArrayEmpty } from '@/utils/utils'
import { setLocale } from '@/services/axios'

export default {
    mixins: [languageMixins],

    props: {
        text: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            drawer: null,
            items: [
                { title: 'Home', icon: 'mdi-view-dashboard' },
                { title: 'About', icon: 'mdi-forum' },
            ],
            isLoading: true,
            active: '/',
            listMenu: [
                {
                    id: 1,
                    title: 'trang chủ',
                    url: '/',
                },
                {
                    id: 2,
                    title: 'về chúng tôi',
                    url: '/abouts',
                },
                {
                    id: 3,
                    title: 'dịch vụ',
                    url: '/services',
                },
                {
                    id: 4,
                    title: 'tin tức sự kiện',
                    url: '/news',
                },
                {
                    id: 5,
                    title: 'tuyển dụng',
                    url: '/recruitment',
                },
                {
                    id: 6,
                    title: 'liên hệ',
                    url: '/contact',
                },
            ],
        }
    },
    created() {
        this.fetchdata()
        const path = this.$router.history.current.path
        this.active = path.includes('/services') ? '/services' : path
    },

    methods: {
        async fetchdata() {
            try {
                const resp = await servicesService.list('type=true')
                if (resp.code === 200 && !isArrayEmpty(resp.data)) {
                    this.$store.state.dataServices = resp.data
                    this.listMenu[2]['subMenu'] = resp.data
                    this.isLoading = false
                }
            } catch (error) {
                console.log(error)
            }
        },
        onClickDetail(slug) {
            window.location = `/services/${slug}`
        },

        redirectUrl(url) {
            this.active = url
            this.$router.push(url)
        },

        onChangeLocale(locale) {
            setLocale(locale)
            location.reload()
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/layouts/_header.scss';
</style>
