import { isStrEmpty } from '@/utils/utils'
import axios from 'axios'
import axiosLib from 'axios'

const axiosAuth = axiosLib.create()

export function setToken(data) {
    localStorage.setItem('access_token', data)
}

export function getAccessToken() {
    return localStorage.getItem('access_token')
}

export function deleteToken() {
    localStorage.removeItem('access_token')
}

export const getUserInfo = () => {
    return localStorage.getItem('user_info') || ''
}

export const setUserInfo = (data) => {
    data = JSON.stringify(data)
    localStorage.setItem('user_info', data)
}

export const deteleUserInfo = () => {
    return localStorage.removeItem('user_info')
}

export const setLocale = (locale: string) => {
    return localStorage.setItem('locale', locale)
}

export const getLocale = () => {
    return localStorage.getItem('locale') || 'vi'
}

export const setLanguage = (language: string) => {
    return localStorage.setItem('language', language)
}

export const getLanguage = () => {
    return localStorage.getItem('language') || ''
}

axiosAuth.interceptors.request.use((request) => {
    const token = getAccessToken()
    if (token) {
        request.headers.Authorization = token
        request.headers.ContentType = 'application/json'
    }

    return request
})

axiosAuth.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        const token = getAccessToken() || ''
        if (!isStrEmpty(token) && error.response.data.msg === 'Invalid Authentication.') {
            deteleUserInfo()
            deleteToken()
            window.location.reload()
        }
        if (error.response && error.response.data) {
            return Promise.reject(error.response.data)
        }

        return Promise.reject(error.message)
    }
)

export default axiosAuth
