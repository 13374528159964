<template>
    <div class="ql__login">
        <v-card class="ql__login-box">
            <h1 class="ql__login-title">đăng nhập</h1>
            <form>
                <v-text-field v-model="email" :error-messages="emailErrors" label="E-mail" required @blur="$v.email.$touch()"></v-text-field>
                <v-text-field v-model="password" :error-messages="passwordErrors" label="Mật Khẩu" type="password" required @blur="$v.password.$touch()"></v-text-field>

                <v-btn class="ql__button" @click="submit" color="primary" :loading="loading"> Đăng Nhập </v-btn>
            </form>
        </v-card>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { setToken, setUserInfo } from '@/services/axios'

import UserService from '@/services/user.service'
import EventBus from '@/utils/EventBus'

export default {
    mixins: [validationMixin],

    validations: {
        password: { required },
        email: { required, email },
    },

    data: () => ({
        email: '',
        password: '',
        loading: false,
    }),

    computed: {
        passwordErrors() {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('Vui Lòng Nhập Mật Khẩu')
            return errors
        },
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.email && errors.push('Định Dạng E-mail không đúng')
            !this.$v.email.required && errors.push('Vui Lòng Nhập E-mail')
            return errors
        },
    },

    methods: {
        async submit() {
            this.loading = true
            this.$v.$touch()
            if (!this.$v.$invalid) {
                try {
                    const param = {
                        email: this.email,
                        password: this.password,
                    }
                    const resp = await UserService.login(param)
                    console.log(resp)

                    if (resp.code === 200) {
                        setToken(resp.data.accessToken)
                        setUserInfo(resp.data.user)
                        this.$router.push('/cms')
                    } else {
                        const param = {
                            text: `E-mail hoặc mật khẩu không đúng`,
                            type: 'error',
                        }
                        EventBus.$emit('show-notications', param)
                    }
                    this.loading = false
                } catch (error) {
                    console.log(error)
                    this.loading = false
                }
            } else {
                this.loading = false
            }
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/cms/login/index.scss';
</style>
