<template>
    <v-dialog class="dialog" v-model="dialog" max-width="290" origin="top top">
        <v-card>
            <v-card-title class="text-h8">{{ getTranslatedText(title) }}</v-card-title>

            <v-card-text> {{ getTranslatedText(description) }} </v-card-text>

            <v-card-actions>
                <v-btn depressed color="error" @click="dialog = false"> Hủy bỏ </v-btn>
                <v-btn depressed color="primary" @click="onConfirmOk"> Đồng ý </v-btn>
                <!-- <ButtonCommon value="cancel" class-name="dialog__box-button__item uppercase" name-function="on-hide-dialog" :isNoHover="true" /> -->
                <!-- <ButtonCommon value="ok" type="error" class-name="dialog__box-button__item uppercase" nameFunction="on-confirm-ok" :isNoHover="true" /> -->
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'
import EventBus from '@/utils/EventBus'
import ButtonCommon from '@/components/common/button/index.vue'
import { isStrEmpty } from '@/utils/utils'

export default {
    mixins: [languageMixins],
    components: { ButtonCommon },
    data() {
        return {
            dialog: false,
            title: 'Xác Nhận',
            description: 'Clear all bets?',
            nameFunction: '',
            paramFunction: null,
        }
    },
    created() {
        EventBus.$on('on-show-dialog', (param) => {
            this.dialog = true
            if (!isStrEmpty(param.title)) this.title = param.title
            if (!isStrEmpty(param.description)) this.description = param.description
            if (!isStrEmpty(param.nameFunction)) this.nameFunction = param.nameFunction
            if (param.paramFunction) this.paramFunction = param.paramFunction
        })
        EventBus.$on('on-hide-dialog', () => (this.dialog = false))
        EventBus.$on('on-confirm-ok', () => this.onConfirmOk())
    },
    destroyed() {
        EventBus.$off('on-show-dialog')
        EventBus.$off('on-hide-dialog')
        EventBus.$off('on-confirm-ok')
    },
    methods: {
        onConfirmOk() {
            EventBus.$emit(this.nameFunction, this.paramFunction)
            this.dialog = false
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/common/_dialog.scss';
</style>
