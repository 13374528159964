import i18n from '@/i18n'
import { getLanguage, getLocale } from '@/services/axios'
import { isArrayEmpty, isStrEmpty } from '@/utils/utils'

export default {
    data(): any {
        return {
            locale: '',
        }
    },

    methods: {
        getTranslatedText(text: string) {
            if (!text) {
                return
            }
            const rsText = text.toLowerCase()
            var translatedText = rsText
            let language: any = getLanguage()
            let locale = getLocale()
            if (!isStrEmpty(language)) {
                language = JSON.parse(getLanguage())
                language = language.filter((x) => x.type === locale && x.key === rsText)
                if (!isArrayEmpty(language)) {
                    translatedText = language[0].value
                }
            }

            return translatedText
        },

        getTranslatedTextData(text: string) {
            if (!text) {
                return
            }
            let key = text
            let locale = getLocale()

            key = !isStrEmpty(locale) && locale !== 'vi' ? `${key}${locale.replace(/\b\w/g, (l) => l.toUpperCase())}` : key

            return key
        },
    },
}
