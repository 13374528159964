<template>
    <div class="ql__services ql__table-common">
        <div class="ql__table-header">
            <TitleCommon text="Giới thiệu" />
        </div>
        <EmptyCommon text="Hiện tại không có tin tức nào" v-if="dataSource.length === 0" />
        <v-simple-table v-else>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left" width="50px">STT</th>
                        <th class="text-left" width="200px">Tiều đề</th>
                        <th class="text-left">Mô tả</th>
                        <th class="text-left" width="100px">Ngôn Ngữ</th>
                        <th class="text-left" width="50px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, key) in dataSource" :key="key">
                        <td>{{ key + 1 }}</td>
                        <td>
                            {{ item.key }}
                        </td>
                        <td>
                            {{ renderDescription(item.value) }}
                        </td>
                        <td>
                            {{ item.type }}
                        </td>
                        <td>
                            <div class="ql__table-common-action">
                                <i class="fa-regular fa-pen-to-square" @click="() => onEdit(item._id)"></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <div class="text-center" v-if="lengthPagination > 1">
            <v-pagination v-model="page" :length="lengthPagination"></v-pagination>
        </div>
    </div>
</template>
<script>
import TitleCommon from '@/components/common/cms/title/index.vue'
import EmptyCommon from '@/components/common/empty/index.vue'
import localeService from '@/services/locale.service'
import { isArrayEmpty, removeTagHtml } from '@/utils/utils'

export default {
    components: { TitleCommon, EmptyCommon },
    data() {
        return {
            page: 1,
            limit: 10,
            lengthPagination: 1,
            dataSource: [],
        }
    },
    created() {
        this.fetchdata()
    },
    methods: {
        async fetchdata() {
            try {
                let filter = `isStatic=true&page=about`
                const resp = await localeService.list(filter)
                if (resp.code === 200 && !isArrayEmpty(resp.data)) {
                    this.dataSource = resp.data
                    let lengthPagination = parseInt(resp.data.length / this.limit)
                    this.lengthPagination = lengthPagination
                }
            } catch (error) {
                console.log(error)
            }
        },

        onEdit(slug) {
            this.$router.push(`/cms/about/update/${slug}`)
        },

        renderDescription(des) {
            let text = removeTagHtml(des)
            return text
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/cms/sevices/index.scss';
</style>
