import { RouteConfig } from 'vue-router'
import HomePage from '@/views/home/index.vue'
import AboutsPage from '@/views/abouts/index.vue'
import NewsPage from '@/views/news/index.vue'
import NewsDetail from '@/views/news/detail.vue'
import ContactPage from '@/views/contact/index.vue'
import ServicesPage from '@/views/services/index.vue'
import ServicesDetail from '@/views/services/detail.vue'

const publicRoutes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home Page',
        component: HomePage,
    },
    {
        path: '/abouts',
        name: 'Abouts Page',
        component: AboutsPage,
    },
    {
        path: '/news',
        name: 'News Page',
        component: NewsPage,
    },
    {
        path: '/news/:slug',
        name: 'News Page',
        component: NewsDetail,
    },
    {
        path: '/contact',
        name: 'Contact Page',
        component: ContactPage,
    },
    {
        path: '/services',
        name: 'services Page',
        component: ServicesPage,
    },
    {
        path: '/services/:slug',
        name: 'services detail',
        component: ServicesDetail,
    },
]

export default publicRoutes
