import BaseService from './base.service'

const API_URL = process.env.VUE_APP_API_URL

interface loginInterFace {
    email: String
    password: String
}
class UserService extends BaseService {
    async login(payload: loginInterFace) {
        let response = await this.post(`${API_URL}/api/v1/user/login`, payload)

        return response.data
    }
}

export default new UserService()
