import BaseService from './base.service'

const API_URL = process.env.VUE_APP_API_URL

class registerServicesService extends BaseService {
    async list() {
        let response = await this.get(`${API_URL}/api/v1/register-services`)

        return response.data
    }

    async detail(id: string) {
        let response = await this.get(`${API_URL}/api/v1/register-services/${id}`)

        return response.data
    }

    async create(param: any) {
        let response = await this.post(`${API_URL}/api/v1/register-services`, param)

        return response.data
    }

    async update(id: string, param: any) {
        let response = await this.patch(`${API_URL}/api/v1/register-services/${id}`, param)

        return response.data
    }

    async deleteItem(id: string) {
        let response = await this.delete(`${API_URL}/api/v1/register-services/${id}`)

        return response.data
    }
}

export default new registerServicesService()
