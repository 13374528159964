import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        dataServices: [],
        isScreenMobile: false,
    },
    getters: {},
    mutations: {
        SET_DATA_SERVICES(state, data) {
            state.dataServices = data
        },
    },
    actions: {
        handleUserInfo({ commit, state }, data) {
            commit('SET_USER_INFO', data)
        },
    },
})
