<template>
    <div class="screen-services home">
        <header class="ql__client-header-common">
            <p class="ql__client-breadcrumbs">
                <span class="active" @click="$router.push('/')">{{ getTranslatedText('trang chủ') }}</span> <span> » </span> <span>{{ getTranslatedText('dịch vụ') }}</span>
            </p>
        </header>
        <div class="home__section">
            <section class="home__section-item service">
                <TitleCommon text="DỊCH VỤ" />
                <v-skeleton-loader class="mx-auto" max-width="90%" type="list-item-two-line, image" v-if="isLoading" />
                <div class="home__service-list" v-else>
                    <EmptyCommon v-if="dataSource.length === 0" text="hiện tại không có dịch vụ nào" />
                    <div class="home__service-item" v-for="(v, i) in dataSource" :key="i">
                        <div class="home__service-item-content">
                            <h4 class="home__title" @click="onShowDetail(v.slug)">{{ v[`${getTranslatedTextData('title')}`] }}</h4>
                            <p class="home__description" v-html="v[`${getTranslatedTextData('description')}`]"></p>
                            <span class="home__more" @click="onShowDetail(v.slug)">{{ getTranslatedText('xem thêm') }}</span>
                        </div>
                        <div class="home__service-item-image">
                            <div class="home__service-item-icon">
                                <img :src="`${VUE_APP_API_URL}/${v.icon}`" />
                            </div>
                            <img :src="`${VUE_APP_API_URL}/${v.image}`" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import TitleCommon from '@/components/common/title/index.vue'
import languageMixins from '@/mixins/language/language-mixins'
import carousel from 'vue-owl-carousel'
import servicesService from '@/services/service.service'
import EmptyCommon from '@/components/common/empty/index.vue'
import { isArrayEmpty } from '@/utils/utils'

const VUE_APP_API_URL = process.env.VUE_APP_API_URL

export default {
    mixins: [languageMixins],
    components: { TitleCommon, carousel, EmptyCommon },
    data: () => {
        return {
            VUE_APP_API_URL: VUE_APP_API_URL,
            dataSource: [],
        }
    },
    created() {
        this.fetchData()
    },
    methods: {
        async fetchData() {
            try {
                const resp = await servicesService.list()
                if (resp.code === 200 && !isArrayEmpty(resp.data)) {
                    this.dataSource = resp.data
                }
                this.isLoading = false
            } catch (error) {
                console.log(error)
            }
        },
        onShowDetail(slug) {
            this.$router.push(`/services/${slug}`)
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/services/_index.scss';
</style>
