<template>
    <div class="home">
        <!-- <BannerComponent /> -->
        <div class="home__section">
            <section class="home__section-item abouts">
                <TitleCommon text="about title home" />
                <p class="home__section-item-text">{{ getTranslatedText('about description home') }}</p>
            </section>
            <ServicesComponent />
            <ServicesOtherComponent />
            <NewsComponent />
        </div>
    </div>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'
import TitleCommon from '@/components/common/title/index.vue'
import BannerComponent from '@/components/home/banner.vue'
import ServicesComponent from '@/components/home/service.vue'
import ServicesOtherComponent from '@/components/home/service-other.vue'
import NewsComponent from '@/components/home/news.vue'

export default {
    mixins: [languageMixins],
    components: { TitleCommon, ServicesComponent, ServicesOtherComponent, NewsComponent, BannerComponent },
    data: () => ({
        items: [
            {
                src: 'https://truongthinhil.com.vn/wp-content/uploads/2022/07/banner02-1024x373.jpg',
                alt: 'Random image',
            },
        ],
    }),
}
</script>

<style lang="scss">
@import '~ThemePath/scss/home/_index.scss';
</style>
