import BaseService from './base.service'

const API_URL = process.env.VUE_APP_API_URL

class servicesService extends BaseService {
    async list(filter: string = '') {
        let response = await this.get(`${API_URL}/api/v1/services?${filter}`)

        return response.data
    }

    async detail(slug: string) {
        let response = await this.get(`${API_URL}/api/v1/services/${slug}`)

        return response.data
    }

    async create(param: any) {
        let response = await this.post(`${API_URL}/api/v1/services`, param)

        return response.data
    }

    async update(slug: string, param: any) {
        let response = await this.patch(`${API_URL}/api/v1/services/${slug}`, param)

        return response.data
    }

    async deleteItem(slug: string) {
        let response = await this.delete(`${API_URL}/api/v1/services/${slug}`)

        return response.data
    }
}

export default new servicesService()
