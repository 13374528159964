<template>
    <div class="ql__menu">
        <div class="ql__menu-item" :class="{ active: active === v.url }" v-for="(v, i) in dataSource" :key="i" @click="onRedirect(v.url)">
            <h2 class="ql__menu-item-title">{{ v.title }}</h2>
        </div>
    </div>
</template>
<script>
import { menuCms } from '@/resource/cms/menu'
export default {
    components: {},
    data() {
        return {
            dataSource: menuCms,
            active: '/',
        }
    },

    created() {},
    mounted() {},
    methods: {
        onRedirect(url) {
            this.$router.push(url)
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/cms/layouts/menu.scss';
</style>
