import axios from 'axios'
import BaseService from './base.service'

const API_URL = process.env.VUE_APP_API_URL

class uploadService extends BaseService {
    async uploadImage(image) {
        let response = await this.post(`${API_URL}/api/v1/upload`, image)
        return response.data
    }

    async removeImage(id: string) {
        let response = await this.delete(`${API_URL}/api/v1/upload/${id}`)

        return response.data
    }
}

export default new uploadService()
