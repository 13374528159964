<template>
    <div class="ql__services ql__table-common">
        <div class="ql__table-header">
            <TitleCommon text="Danh Sách Ngôn ngữ" />
            <v-btn class="ma-2" color="primary" @click="$router.push('/cms/locales/create')"> tạo mới </v-btn>
        </div>
        <EmptyCommon text="Hiện tại không có Ngôn ngữ nào" v-if="dataSource.length === 0" />
        <v-simple-table v-else>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left" width="50px">STT</th>
                        <th class="text-left">Tiều đề</th>
                        <th class="text-left">Mô tả</th>
                        <th class="text-left" width="100px">Ngôn Ngữ</th>
                        <th class="text-left" width="100px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, key) in dataSource" :key="key">
                        <td>{{ key + 1 }}</td>
                        <td>
                            {{ item.key }}
                        </td>
                        <td>
                            {{ item.value }}
                        </td>
                        <td>
                            {{ item.type }}
                        </td>
                        <td>
                            <div class="ql__table-common-action">
                                <i class="fa-regular fa-pen-to-square" @click="() => onEdit(item._id)"></i>
                                <i class="fa fa-trash" @click="() => onShowConfirmRemoveItem(item._id)"></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <div class="text-center" v-if="lengthPagination > 1">
            <v-pagination v-model="page" :length="lengthPagination"></v-pagination>
        </div>
    </div>
</template>
<script>
import TitleCommon from '@/components/common/cms/title/index.vue'
import EmptyCommon from '@/components/common/empty/index.vue'
import localeService from '@/services/locale.service'
import EventBus from '@/utils/EventBus'
import { formatTimestamp, isArrayEmpty } from '@/utils/utils'

export default {
    components: { TitleCommon, EmptyCommon },
    data() {
        return {
            page: 1,
            limit: 10,
            lengthPagination: 1,
            dataSource: [],
        }
    },
    created() {
        this.fetchdata()
        EventBus.$on('on-remove-item-locales', (param) => this.onRemoveItem(param))
    },
    destroyed() {
        EventBus.$off('on-remove-item-locales')
    },
    mounted() {},
    methods: {
        async fetchdata() {
            try {
                let filter = `isStatic=false`
                const resp = await localeService.list(filter)
                if (resp.code === 200 && !isArrayEmpty(resp.data)) {
                    this.dataSource = resp.data
                    let lengthPagination = parseInt(resp.data.length / this.limit)
                    this.lengthPagination = lengthPagination
                }
            } catch (error) {
                console.log(error)
            }
        },

        onShowConfirmRemoveItem(id) {
            const param = {
                description: 'bạn có chắc chắn muốn xóa tin tức này không?',
                nameFunction: 'on-remove-item-locales',
                paramFunction: {
                    id,
                },
            }
            EventBus.$emit('on-show-dialog', param)
        },

        async onRemoveItem(param) {
            try {
                const resp = await localeService.deleteItem(param.id)
                if (resp.code === 200) {
                    const index = this.dataSource.findIndex((x) => x._id === param.id)
                    if (index !== 0) {
                        this.dataSource.splice(index, 1)
                        let message = {
                            text: resp.data.msg,
                            type: 'success',
                        }
                        EventBus.$emit('show-notications', message)
                    }
                } else {
                    let message = {
                        text: resp.data.msg,
                        type: 'error',
                    }
                    EventBus.$emit('show-notications', message)
                    this.loading = false
                }
            } catch (error) {
                console.log(error)
            }
        },

        onEdit(slug) {
            this.$router.push(`/cms/locales/update/${slug}`)
        },

        formatDate(time) {
            const dateFormat = formatTimestamp(time)
            return dateFormat
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/cms/sevices/index.scss';
</style>
