<template>
    <section class="home__section-item news" v-if="!isLoading && dataSource.length > 0">
        <TitleCommon text="TIN TỨC & SỰ KIỆN" />
        <v-skeleton-loader class="mx-auto" max-width="90%" type="list-item-two-line, image" v-if="isLoading" />
        <template v-else>
            <carousel :navText="['<', '>']" :loop="true" :margin="30" :autoplayTimeout="200" :responsive="{ 0: { items: 1, nav: true, dots: false }, 769: { items: 4, nav: true, dots: false } }">
                <div class="home__news-item" v-for="(item, index) in dataSource" :key="index">
                    <img :src="`${VUE_APP_API_URL}/${item.image}`" />
                    <h4 class="home__title" @click="onShowDetail(item.slug)">{{ item[`${getTranslatedTextData('title')}`] }}</h4>
                    <p class="home__news-item-description">{{ renderDescription(item[`${getTranslatedTextData('description')}`]) }}</p>
                    <button class="home__news-item-more" @click="onShowDetail(item.slug)">
                        {{ getTranslatedText('đọc thêm') }}
                        <img src="~ThemePath/images/double-right.png" />
                    </button>
                </div>
            </carousel>
        </template>
    </section>
</template>

<script>
import TitleCommon from '@/components/common/title/index.vue'
import languageMixins from '@/mixins/language/language-mixins'
import newsService from '@/services/news.service'
import carousel from 'vue-owl-carousel'
const VUE_APP_API_URL = process.env.VUE_APP_API_URL

import { isArrayEmpty, removeTagHtml } from '@/utils/utils'

export default {
    mixins: [languageMixins],
    components: { TitleCommon, carousel },
    data: () => ({
        dataSource: [],
        VUE_APP_API_URL: VUE_APP_API_URL,
        isLoading: true,
    }),
    created() {
        this.fetchData()
    },
    methods: {
        async fetchData() {
            try {
                const resp = await newsService.list()
                if (resp.code === 200 && !isArrayEmpty(resp.data)) {
                    this.dataSource = resp.data
                }
                this.isLoading = false
            } catch (error) {
                console.log(error)
            }
        },

        renderDescription(des) {
            let text = removeTagHtml(des)
            return text
        },

        onShowDetail(slug) {
            this.$router.push(`/news/${slug}`)
        },
    },
}
</script>
