<template>
    <div class="screen-abouts">
        <header class="ql__client-header-common">
            <p class="ql__client-breadcrumbs">
                <span class="active">{{ getTranslatedText('trang chủ') }}</span> <span> » </span> <span>{{ getTranslatedText('về chúng tôi') }}</span>
            </p>
        </header>
        <TitleCommon text="về chúng tôi" />
        <div class="screen-abouts-description" v-html="getTranslatedText('about description')"></div>
    </div>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import TitleCommon from '@/components/common/title/index.vue'

export default {
    mixins: [languageMixins],
    components: { TitleCommon },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/abouts/_index.scss';
</style>
