<template>
    <div class="ql__services ql__table-common">
        <div class="ql__table-header">
            <TitleCommon text="Danh Sách Dịch Vụ" />
            <v-btn class="ma-2" color="primary" @click="$router.push('/cms/servives/create')"> tạo mới </v-btn>
        </div>
        <EmptyCommon text="Hiện tại không có dịch vụ nào" v-if="dataSource.length === 0" />
        <v-simple-table v-else>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left" width="50px">STT</th>
                        <th class="text-left" width="150px">Tiêu đề</th>
                        <th class="text-left">Mô tả</th>
                        <th class="text-left" width="100px">Ngày Tạo</th>
                        <th class="text-left" width="100px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, key) in dataSource" :key="key">
                        <td>{{ key + 1 }}</td>
                        <td>{{ item.title }}</td>
                        <td>
                            <p class="limit-row" v-html="item.description"></p>
                        </td>
                        <td>{{ formatDate(item.createAt) }}</td>
                        <td>
                            <div class="ql__table-common-action">
                                <i class="fa-regular fa-pen-to-square" @click="() => onEdit(item.slug)"></i>
                                <i class="fa fa-trash" @click="() => onShowConfirmRemoveItem(item.slug)"></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <div class="text-center" v-if="lengthPagination > 1">
            <v-pagination v-model="page" :length="lengthPagination"></v-pagination>
        </div>
    </div>
</template>
<script>
import servicesService from '@/services/service.service'
import TitleCommon from '@/components/common/cms/title/index.vue'
import EmptyCommon from '@/components/common/empty/index.vue'
import EventBus from '@/utils/EventBus'
import { formatTimestamp, isArrayEmpty } from '@/utils/utils'
export default {
    components: { TitleCommon, EmptyCommon },
    data() {
        return {
            page: 1,
            limit: 10,
            lengthPagination: 1,
            dataSource: [],
        }
    },
    created() {
        this.fetchdata()
        EventBus.$on('on-remove-item-service', (param) => this.onRemoveItem(param))
    },
    destroyed() {
        EventBus.$off('on-remove-item-service')
    },
    mounted() {},
    methods: {
        async fetchdata() {
            try {
                const resp = await servicesService.list()
                if (resp.code === 200 && !isArrayEmpty(resp.data)) {
                    console.log(resp)
                    this.dataSource = resp.data
                    let lengthPagination = parseInt(resp.data.length / this.limit)
                    this.lengthPagination = lengthPagination
                }
            } catch (error) {
                console.log(error)
            }
        },

        onShowConfirmRemoveItem(slug) {
            const param = {
                description: 'bạn có chắc chắn muốn xóa dịch vụ này không?',
                nameFunction: 'on-remove-item-service',
                paramFunction: {
                    slug,
                },
            }
            EventBus.$emit('on-show-dialog', param)
        },

        async onRemoveItem(param) {
            console.log(param)

            try {
                const resp = await servicesService.deleteItem(param.slug)
                if (resp.code === 200) {
                    const index = this.dataSource.findIndex((x) => x.slug === param.slug)
                    if (index !== 0) {
                        this.dataSource.splice(index, 1)
                        let message = {
                            text: resp.data.msg,
                            type: 'success',
                        }
                        EventBus.$emit('show-notications', message)
                    }
                } else {
                    let message = {
                        text: resp.data.msg,
                        type: 'error',
                    }
                    EventBus.$emit('show-notications', message)
                    this.loading = false
                }
            } catch (error) {
                console.log(error)
            }
        },

        onEdit(slug) {
            this.$router.push(`/cms/servives/update/${slug}`)
        },

        formatDate(time) {
            const dateFormat = formatTimestamp(time)
            return dateFormat
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/cms/sevices/index.scss';
</style>
