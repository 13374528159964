import BaseService from './base.service'

const API_URL = process.env.VUE_APP_API_URL

class localeService extends BaseService {
    async list(type: string = '') {
        let response = await this.get(`${API_URL}/api/v1/locale?${type}`)

        return response.data
    }

    async detail(id: string) {
        let response = await this.get(`${API_URL}/api/v1/locale/${id}`)

        return response.data
    }

    async create(param: any) {
        let response = await this.post(`${API_URL}/api/v1/locale`, param)

        return response.data
    }

    async update(slug: string, param: any) {
        let response = await this.patch(`${API_URL}/api/v1/locale/${slug}`, param)

        return response.data
    }

    async deleteItem(slug: string) {
        let response = await this.delete(`${API_URL}/api/v1/locale/${slug}`)

        return response.data
    }
}

export default new localeService()
