<template>
    <div class="ql__services ql__table-common">
        <div class="ql__table-header">
            <TitleCommon text="Danh Sách Banners" />
            <v-btn class="ma-2" color="primary" @click="$router.push('/cms/banners/create')"> tạo mới </v-btn>
        </div>
        <EmptyCommon text="Hiện tại banners nào" v-if="dataSource.length === 0" />
        <v-simple-table v-else>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left" width="50px">STT</th>
                        <th class="text-left">Hình Ảnh</th>
                        <th class="text-left" width="100px">Ưu tiên</th>
                        <th class="text-left" width="100px">Ngày Tạo</th>
                        <th class="text-left" width="100px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, key) in dataSource" :key="key">
                        <td>{{ key + 1 }}</td>
                        <td><img width="150px" :src="`${VUE_APP_API_URL}/${item.image}`" class="uploaded-image" /></td>
                        <td>
                            {{ item.order }}
                        </td>
                        <td>{{ formatDate(item.createAt) }}</td>
                        <td>
                            <div class="ql__table-common-action">
                                <i class="fa-regular fa-pen-to-square" @click="() => onEdit(item._id)"></i>
                                <i class="fa fa-trash" @click="() => onShowConfirmRemoveItem(item._id)"></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <div class="text-center" v-if="lengthPagination > 1">
            <v-pagination v-model="page" :length="lengthPagination"></v-pagination>
        </div>
    </div>
</template>
<script>
import TitleCommon from '@/components/common/cms/title/index.vue'
import EmptyCommon from '@/components/common/empty/index.vue'
import EventBus from '@/utils/EventBus'
import { formatTimestamp, isArrayEmpty, removeTagHtml } from '@/utils/utils'
import bannerService from '@/services/banner.service'
import uploadService from '@/services/upload.service'
const VUE_APP_API_URL = process.env.VUE_APP_API_URL

export default {
    components: { TitleCommon, EmptyCommon },
    data() {
        return {
            page: 1,
            limit: 10,
            lengthPagination: 1,
            dataSource: [],
            VUE_APP_API_URL: VUE_APP_API_URL,
        }
    },
    created() {
        this.fetchdata()
        EventBus.$on('on-remove-item-banner', (param) => this.onRemoveItem(param))
    },
    destroyed() {
        EventBus.$off('on-remove-item-banner')
    },
    mounted() {},
    methods: {
        async fetchdata() {
            try {
                const resp = await bannerService.list()
                console.log(resp)
                if (resp.code === 200 && !isArrayEmpty(resp.data)) {
                    this.dataSource = resp.data
                    let lengthPagination = parseInt(resp.data.length / this.limit)
                    this.lengthPagination = lengthPagination
                }
            } catch (error) {
                console.log(error)
            }
        },

        onShowConfirmRemoveItem(id) {
            const param = {
                description: 'bạn có chắc chắn muốn xóa banner này không?',
                nameFunction: 'on-remove-item-banner',
                paramFunction: {
                    id,
                },
            }
            EventBus.$emit('on-show-dialog', param)
        },

        async onRemoveItem(param) {
            try {
                let { id } = param
                const resp = await bannerService.deleteItem(id)
                if (resp.code === 200) {
                    const index = this.dataSource.findIndex((x) => x._id === id)
                    if (index !== -1) {
                        let id = this.dataSource[index].image.replace(`static/`, '')
                        uploadService.removeImage(id)

                        this.dataSource.splice(index, 1)
                        let message = {
                            text: resp.data.msg,
                            type: 'success',
                        }
                        EventBus.$emit('show-notications', message)
                    }
                } else {
                    let message = {
                        text: resp.data.msg,
                        type: 'error',
                    }
                    EventBus.$emit('show-notications', message)
                    this.loading = false
                }
            } catch (error) {
                console.log(error)
            }
        },

        onEdit(id) {
            this.$router.push(`/cms/banners/update/${id}`)
        },

        renderDescription(des) {
            let text = removeTagHtml(des)
            return text
        },

        formatDate(time) {
            const dateFormat = formatTimestamp(time)
            return dateFormat
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/cms/sevices/index.scss';
</style>
