<template>
    <div class="screen-news">
        <TitleCommon text="TIN TỨC – SỰ KIỆN" />
        <v-skeleton-loader class="mx-auto" max-width="90%" type="list-item-two-line, image" v-if="isLoading" />
        <div class="screen-news__list" v-else>
            <div class="screen-news__item" v-for="(item, i) in dataSource" :key="i">
                <div class="screen-news__item-image" @click="() => onShowDetail(item.slug)">
                    <img :src="`${VUE_APP_API_URL}/${item.image}`" />
                </div>
                <div class="screen-news__item-content">
                    <h5 class="screen-news__item-content-title" @click="() => onShowDetail(item.slug)">{{ item.title }}</h5>
                    <p class="screen-news__item-content-description">{{ renderDescription(item.description) }}</p>
                    <ButtomCommon value="đọc thêm" type="error" @onClickButton="() => onShowDetail(item.slug)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import TitleCommon from '@/components/common/title/index.vue'
import ButtomCommon from '@/components/common/button/index.vue'
import newsService from '@/services/news.service'
import { isArrayEmpty, removeTagHtml } from '@/utils/utils'
const VUE_APP_API_URL = process.env.VUE_APP_API_URL

export default {
    mixins: [languageMixins],
    components: { TitleCommon, ButtomCommon },
    props: {
        text: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        dataSource: [],
        VUE_APP_API_URL: VUE_APP_API_URL,
        isLoading: true,
    }),
    created() {
        this.fetchData()
    },
    methods: {
        async fetchData() {
            try {
                const resp = await newsService.list()
                if (resp.code === 200 && !isArrayEmpty(resp.data)) {
                    this.dataSource = resp.data
                    this.isLoading = false
                }
            } catch (error) {
                console.log(error)
            }
        },

        renderDescription(des) {
            let text = removeTagHtml(des)
            return text
        },

        onShowDetail(slug) {
            this.$router.push(`/news/${slug}`)
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/news/_index.scss';
</style>
