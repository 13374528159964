<template>
    <div class="ql__home">Home</div>
</template>
<script>
export default {
    components: {},
    data() {
        return {}
    },

    created() {},
    mounted() {},
    methods: {},
}
</script>

<style lang="scss">
// @import '~ThemePath/scss/main.scss';
</style>
