/* eslint-disable max-len */
import { RouteConfig } from 'vue-router'
import { metaRequireGuest } from './meta-constants'
import LoginCms from '@/views/cms/login/index.vue'

const authenticateRoutes: Array<RouteConfig> = [
    {
        path: '/cms/login',
        name: 'Cms Login',
        meta: {
            isCms: true,
            ...metaRequireGuest,
        },
        component: LoginCms,
    },
]

export default authenticateRoutes
