<template>
    <section class="home__section-item service" v-if="!isLoading && dataSource.length > 0">
        <TitleCommon text="NGOÀI RA CHÚNG TÔI CUNG CẤP CÁC DỊCH VỤ KHÁC" />
        <v-skeleton-loader class="mx-auto" max-width="90%" type="list-item-two-line, image" v-if="isLoading" />
        <div class="home__other-services-list" v-else>
            <div class="home__other-services-item" v-for="(v, i) in dataSource" :key="i">
                <img src="~ThemePath/images/service/icon_transport_3.png" />
                <h4 class="home__title" @click="$router.push(`/services/${v.slug}`)">{{ v[`${getTranslatedTextData('title')}`] }}</h4>
                <p class="home__other-services-desciption" v-html="v[`${getTranslatedTextData('description')}`]"></p>
            </div>
        </div>
    </section>
</template>

<script>
import TitleCommon from '@/components/common/title/index.vue'
import languageMixins from '@/mixins/language/language-mixins'
import servicesService from '@/services/service.service'
import { isArrayEmpty } from '@/utils/utils'

export default {
    mixins: [languageMixins],
    components: { TitleCommon },
    data: () => ({
        dataSource: [],
        isLoading: true,
    }),
    created() {
        this.fetchData()
    },
    methods: {
        async fetchData() {
            try {
                const resp = await servicesService.list('type=false')
                if (resp.code === 200 && !isArrayEmpty(resp.data)) {
                    this.dataSource = resp.data
                }
                this.isLoading = false
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>
