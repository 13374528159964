<template>
    <footer class="footer">
        <div class="footer-content">
            <div class="footer-item">
                <span class="footer-item__title">{{ getTranslatedText('VỀ CHÚNG TÔI footer') }}</span>
                <p class="footer-text">{{ getTranslatedText('về chúng tôi 1') }}</p>
                <p class="footer-text">{{ getTranslatedText('về chúng tôi 2') }}</p>
                <p class="footer-text">{{ getTranslatedText('về chúng tôi 3') }}</p>
                <p class="footer-text">{{ getTranslatedText('về chúng tôi 4') }}</p>
            </div>
            <div class="footer-item">
                <span class="footer-item__title">{{ getTranslatedText('danh muc') }}</span>
                <ul class="footer-item__service">
                    <li class="footer-item__service-item" v-for="(item, index) in $store.state.dataServices" :key="index">
                        <span class="footer-text hl">{{ item[getTranslatedTextData('title')] }}</span>
                    </li>
                </ul>
            </div>
            <div class="footer-item">
                <span class="footer-item__title">{{ getTranslatedText('THÔNG TIN LIÊN HỆ') }}</span>
                <p class="footer-text">{{ getTranslatedText('tên công ty') }}</p>
                <div class="footer-item__information">
                    <div class="footer-item__information-item">
                        <span class="footer-text">{{ getTranslatedText('Địa chỉ') }}: </span>
                        <span class="footer-text hl">{{ getTranslatedText('địa chỉ công ty') }}</span>
                    </div>
                    <div class="footer-item__information-item">
                        <span class="footer-text">{{ getTranslatedText('Hotline') }}: </span>
                        <span class="footer-text hl">{{ getTranslatedText('Hotline công ty') }}</span>
                    </div>
                    <div class="footer-item__information-item">
                        <span class="footer-text">{{ getTranslatedText('Tel') }}: </span>
                        <span class="footer-text hl">{{ getTranslatedText('Tel công ty') }}</span>
                    </div>
                </div>
            </div>
            <div class="footer-item">
                <span class="footer-item__title">{{ getTranslatedText('bản đồ chỉ đường') }}</span>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1937.3709623836062!2d109.17495993624426!3d13.794425078501673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x316f1540efd82a4f%3A0xaaf96f9a6d41543f!2sC%C3%94NG%20TY%20TNHH%20LOGISTICS%20NH%C6%AF%20ANH!5e0!3m2!1svi!2s!4v1684487634236!5m2!1svi!2s"
                    width="350"
                    height="100%"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </div>
        <div class="footer-copyright">
            <p>{{ getTranslatedText('copyright') }}</p>
        </div>
    </footer>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'

export default {
    mixins: [languageMixins],

    props: {
        text: {
            type: String,
            default: '',
        },
    },
    data() {
        return {}
    },
    methods: {},
}
</script>

<style lang="scss">
@import '~ThemePath/scss/layouts/_footer.scss';
</style>
