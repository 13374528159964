<template>
    <v-app>
        <LoadingCommon v-if="isLoading" />
        <template v-else>
            <template v-if="$route.meta.isCms">
                <div class="ql__app">
                    <template v-if="$route.meta.guest">
                        <router-view />
                    </template>
                    <template v-else>
                        <HeaderCms />
                        <div class="ql__app-container">
                            <div class="ql__app-container-menu">
                                <MenuCms />
                            </div>
                            <div class="ql__app-container-content">
                                <router-view />
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <template v-else>
                <Header />
                <main class="main-container">
                    <router-view />
                </main>
                <FormRegister />
                <Footer />
            </template>
        </template>

        <Notications />
        <DialogComponent />
    </v-app>
</template>
<script>
import Notications from '@/components/common/notications/index.vue'
import DialogComponent from '@/components/common/dialog/index.vue'
import LoadingCommon from '@/components/common/loading/index.vue'
import Header from '@/components/layouts/header.vue'
import Footer from '@/components/layouts/footer.vue'
import FormRegister from '@/components/layouts/form-register.vue'
import HeaderCms from '@/components/cms/layout/header.vue'
import MenuCms from '@/components/cms/layout/menu.vue'
import localeService from './services/locale.service'
import { isArrayEmpty } from './utils/utils'
import { getLocale, setLanguage, setLocale } from './services/axios'

export default {
    components: {
        Notications,
        DialogComponent,
        Header,
        Footer,
        FormRegister,
        HeaderCms,
        MenuCms,
        LoadingCommon,
    },
    data: () => ({
        isLoading: false,
        screenWidth: window.innerWidth,
    }),
    created() {
        this.fetchLocale()
        if (this.screenWidth <= 768) {
            console.log('??????')
            this.$store.state.isScreenMobile = true
        } else {
            this.$store.state.isScreenMobile = false
        }
        window.addEventListener('resize', this.handleResize)
    },
    watch: {
        screenWidth(value) {
            if (value <= 768) {
                this.$store.state.isScreenMobile = true
            } else {
                this.$store.state.isScreenMobile = false
            }
        },
    },
    methods: {
        async fetchLocale() {
            this.isLoading = true
            try {
                const resp = await localeService.list()
                if (resp.code === 200 && !isArrayEmpty(resp.data)) {
                    setLanguage(JSON.stringify(resp.data))
                }
                let locale = getLocale()
                setLocale(locale)
                this.isLoading = false
            } catch (error) {
                console.log(error)
            }
        },

        handleResize() {
            this.screenWidth = window.innerWidth
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/main.scss';
</style>
