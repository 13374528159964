export const menuCms = [
    {
        title: 'tài khoản',
        url: '/cms/users',
    },
    {
        title: 'giới thiệu',
        url: '/cms/about',
    },
    {
        title: 'banners',
        url: '/cms/banners',
    },
    {
        title: 'dịch vụ',
        url: '/cms/servives',
    },
    {
        title: 'tin tức',
        url: '/cms/news',
    },
    {
        title: 'ngôn ngữ',
        url: '/cms/locales',
    },
    {
        title: 'đăng kí liên hệ',
        url: '/cms/register-services',
    },
]
