import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import axios from 'axios'
import vuetify from '@/plugins/vuetify'
import i18n from './i18n'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


Vue.config.productionTip = false

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC0cBJ2WS2aV66MK8hyYcEecZb3qh5Uc3s",
  authDomain: "logistics-app-96c4d.firebaseapp.com",
  projectId: "logistics-app-96c4d",
  storageBucket: "logistics-app-96c4d.appspot.com",
  messagingSenderId: "818165778923",
  appId: "1:818165778923:web:f6e91e132102ec9789b1ae",
  measurementId: "G-MGGB156BTR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount('#app')
