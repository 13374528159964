<template>
    <section class="ql__form-register">
        <div class="ql__form-register-form">
            <p class="ql__form-register-form__label">{{ getTranslatedText('ĐĂNG KÝ DỊCH VỤ VẬN TẢI') }}</p>
            <div class="ql__form-register-form__input">
                <input v-model="fullName" :placeholder="getTranslatedText('họ và tên')" />
                <input v-model="email" :placeholder="getTranslatedText('email')" />
                <input v-model="phone" :placeholder="getTranslatedText('điện thoại')" />
                <button class="ql__button" @click="validateForm" color="primary" :loading="loading">{{ getTranslatedText('Đăng kí') }}</button>
            </div>
        </div>
    </section>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import registerServicesService from '@/services/register-services.service'
import EventBus from '@/utils/EventBus'
import { isStrEmpty, validateEmail, validatePhoneNumber } from '@/utils/utils'

export default {
    mixins: [languageMixins],
    data() {
        return {
            fullName: '',
            email: '',
            phone: '',
            isConnected: false,
            loading: false,
        }
    },
    methods: {
        validateForm() {
            let message = ''
            if (isStrEmpty(fullName)) {
                message = this.getTranslatedText('vui lòng nhập họ và tên')
            } else if (isStrEmpty(email)) {
                message = this.getTranslatedText('vui lòng nhập email')
            } else if (!isStrEmpty(email) && !validateEmail(email)) {
                message = this.getTranslatedText('vui lòng nhập lại email đúng định dạng')
            } else if (isStrEmpty(phone)) {
                message = this.getTranslatedText('vui lòng nhập số điện thoại')
            } else if (!isStrEmpty(phone) && !validatePhoneNumber(phone)) {
                message = this.getTranslatedText('số điện thoại không đúng định dạng')
            }

            if (!isStrEmpty(message)) {
                this.submit()
                return false
            }

            let param = {
                text: message,
                type: 'error',
            }
            EventBus.$emit('show-notications', param)
        },

        async submit() {
            try {
                const { fullName, email, phone, isConnected } = this
                let param = { fullName, email, phone, isConnected }
                const resp = await registerServicesService.create(param)
                console.log(resp)
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/layouts/_register.scss';
</style>
