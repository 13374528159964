<template>
    <div class="empty">
        <span class="empty-text">{{ getTranslatedText(text) }}</span>
    </div>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'

export default {
    mixins: [languageMixins],

    props: {
        text: {
            type: String,
            default: '',
        },
    },
    data() {
        return {}
    },
    methods: {},
}
</script>

<style lang="scss">
@import '~ThemePath/scss/common/_empty.scss';
</style>
