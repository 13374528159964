export const localesDefault = [
    {
        label: 'Tiếng Việt',
        value: 'vi',
    },
    {
        label: 'Tiếng Anh',
        value: 'en',
    },
]
