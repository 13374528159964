<template>
    <div class="ql__services-detail">
        <div class="ql__table-header">
            <TitleCommon text="tạo mới dịch vụ" />
        </div>
        <form class="ql__form-common">
            <UploadCommon @on-get-file-image="onGetFileImage" />

            <div class="ql__form-item-row">
                <div class="ql__form-item icon">
                    <span class="ql__form-item__title">Icon</span>
                    <UploadCommon @on-get-file-image="onGetFileImageIcon" />
                </div>

                <div class="ql__form-item">
                    <v-text-field v-model="title" :error-messages="titleErrors" label="Tiêu đề" required @blur="onBlurTitle"></v-text-field>
                    <v-text-field v-model="titleEn" :error-messages="titleEnErrors" label="Tiêu Đề EN" required></v-text-field>
                </div>
            </div>
            <v-text-field v-model="slug" label="Slug" disabled></v-text-field>
            <div class="ql__form-item">
                <span class="ql__form-item__title">Nội dung</span>
                <vue-editor id="editor" useCustomImageHandler @image-added="handleImageAdded" v-model="description"> </vue-editor>
            </div>
            <div class="ql__form-item no-mr">
                <span class="ql__form-item__title">Nội dung En</span>
                <vue-editor id="editor" useCustomImageHandler @image-added="handleImageAdded" v-model="descriptionEn"> </vue-editor>
            </div>

            <div class="ql__form-item">
                <v-checkbox v-model="type" label="Đặc Biệt" color="primary"></v-checkbox>
            </div>
            <div class="ql__form-common-box-button">
                <v-btn class="ql__button" @click="submit" color="primary" :loading="loading"> Tạo Mới </v-btn>
            </div>
        </form>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { VueEditor } from 'vue2-editor'
import { changeToSlug } from '@/utils/utils'
import EventBus from '@/utils/EventBus'
import servicesService from '@/services/service.service'
import uploadService from '@/services/upload.service'
import UploadCommon from '@/components/common/upload/index.vue'
import TitleCommon from '@/components/common/cms/title/index.vue'

export default {
    mixins: [validationMixin],
    validations: {
        titleEn: { required },
        title: { required },
        slug: { required },
    },
    components: { VueEditor, UploadCommon, TitleCommon },
    data() {
        return {
            loading: false,
            title: '',
            titleEn: '',
            slug: '',
            description: '',
            descriptionEn: '',
            type: true,
            icon: '',
            image: '',
        }
    },
    created() {},
    computed: {
        titleEnErrors() {
            const errors = []
            if (!this.$v.titleEn.$dirty) return errors
            !this.$v.titleEn.required && errors.push('Không được để trống')
            return errors
        },
        titleErrors() {
            const errors = []
            if (!this.$v.title.$dirty) return errors
            !this.$v.title.required && errors.push('Không được để trống')
            return errors
        },
    },
    methods: {
        async submit() {
            try {
                this.loading = true
                this.$v.$touch()
                if (!this.$v.$invalid) {
                    const { title, titleEn, slug, description, descriptionEn, type, icon, image } = this
                    let param = {
                        title,
                        titleEn,
                        slug,
                        description,
                        descriptionEn,
                        type,
                        icon,
                        image,
                    }
                    const resp = await servicesService.create(param)
                    if (resp.code === 200) {
                        let message = {
                            text: `Tạo mới thành công`,
                            type: 'success',
                        }
                        EventBus.$emit('show-notications', message)
                        this.$router.push('/cms/servives')
                    } else {
                        let message = {
                            text: resp.data.msg,
                            type: 'error',
                        }
                        EventBus.$emit('show-notications', message)
                        this.loading = false
                    }
                } else {
                    this.loading = false
                }
            } catch (error) {
                this.loading = false
                console.log(error)
            }
        },

        onGetFileImage(image) {
            this.image = image
        },

        onGetFileImageIcon(icon) {
            this.icon = icon
        },

        async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
            try {
                var formData = new FormData()
                formData.append('image', file)
                let resp = await uploadService.uploadImage(formData)
                if (resp.code === 200) {
                    const url = `${VUE_APP_API_URL}/${resp.data.path}`
                    Editor.insertEmbed(cursorLocation, 'image', url)
                    resetUploader()
                }
            } catch (error) {
                console.log(error)
            }
        },

        onBlurTitle() {
            this.slug = changeToSlug(this.title)
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/cms/sevices/detail.scss';
</style>
