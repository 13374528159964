<template>
    <div class="ql__services-detail">
        <div class="ql__table-header">
            <TitleCommon text="tạo mới ngôn ngữ" />
        </div>
        <form class="ql__form-common">
            <v-text-field v-model="key" :error-messages="keyErrors" label="Tiêu đề" required></v-text-field>
            <v-text-field v-model="value" :error-messages="valueErrors" label="Giá trị" required></v-text-field>
            <v-radio-group v-model="type" row>
                <v-radio :label="item.label" :value="item.value" v-for="(item, i) in localesDefault" :key="i"></v-radio>
            </v-radio-group>
            <div class="ql__form-common-box-button">
                <v-btn class="ql__button" @click="submit" color="primary" :loading="loading"> Tạo Mới </v-btn>
            </div>
        </form>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { localesDefault } from '@/resource/cms/locale'
import TitleCommon from '@/components/common/cms/title/index.vue'
import EventBus from '@/utils/EventBus'
import localeService from '@/services/locale.service'

export default {
    mixins: [validationMixin],
    validations: {
        key: { required },
        value: { required },
    },
    components: { TitleCommon },
    data() {
        return {
            loading: false,
            key: '',
            value: '',
            type: 'vi',
            localesDefault,
        }
    },
    created() {},
    computed: {
        valueErrors() {
            const errors = []
            if (!this.$v.value.$dirty) return errors
            !this.$v.value.required && errors.push('Không được để trống')
            return errors
        },
        keyErrors() {
            const errors = []
            if (!this.$v.key.$dirty) return errors
            !this.$v.key.required && errors.push('Không được để trống')
            return errors
        },
    },
    methods: {
        async submit() {
            try {
                this.loading = true
                this.$v.$touch()
                if (!this.$v.$invalid) {
                    let { key, value, type } = this
                    key = key.toLowerCase().trim()
                    let param = {
                        key,
                        value,
                        type,
                    }
                    const resp = await localeService.create(param)
                    if (resp.code === 200) {
                        let message = {
                            text: `Tạo mới thành công`,
                            type: 'success',
                        }
                        EventBus.$emit('show-notications', message)
                        this.$router.push('/cms/locales')
                    } else {
                        let message = {
                            text: resp.data.msg,
                            type: 'error',
                        }
                        EventBus.$emit('show-notications', message)
                        this.loading = false
                    }
                } else {
                    this.loading = false
                }
            } catch (error) {
                this.loading = false
                console.log(error)
            }
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/cms/sevices/detail.scss';
</style>
