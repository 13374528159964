<template>
    <div class="ql__services-detail">
        <LoadingCommon v-if="isLoadingScreen" />
        <template v-else>
            <div class="ql__table-header">
                <TitleCommon text="Cập nhật Liên Hệ" />
            </div>
            <form class="ql__form-common">
                <v-text-field v-model="email" :error-messages="emailErrors" label="Email" required></v-text-field>
                <v-text-field v-model="fullName" :error-messages="fullNameErrors" label="Tên" required></v-text-field>
                <v-text-field v-model="phone" :error-messages="phoneErrors" label="Điện thoại"></v-text-field>
                <div class="ql__form-item">
                    <v-checkbox v-model="isConnected" label="Liên Hệ" color="primary"></v-checkbox>
                </div>
                <div class="ql__form-common-box-button">
                    <v-btn class="ql__button" @click="submit" color="primary" :loading="loading"> Cập nhật </v-btn>
                </div>
            </form>
        </template>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { isObjEmpty, validatePhoneNumber } from '@/utils/utils'
import TitleCommon from '@/components/common/cms/title/index.vue'
import LoadingCommon from '@/components/common/loading/index.vue'
import EventBus from '@/utils/EventBus'
import registerServicesService from '@/services/register-services.service'

export default {
    mixins: [validationMixin],
    validations: {
        fullName: { required },
        email: { required, email },
        phone: { required },
    },
    components: { TitleCommon, LoadingCommon },
    data() {
        return {
            fullName: '',
            email: '',
            phone: '',
            isConnected: false,
            loading: false,
            isLoadingScreen: true,
        }
    },
    created() {
        this.fetchdata()
    },
    computed: {
        fullNameErrors() {
            const errors = []
            if (!this.$v.fullName.$dirty) return errors
            !this.$v.fullName.required && errors.push('Không được để trống')
            return errors
        },
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.email && errors.push('Định Dạng E-mail không đúng')
            !this.$v.email.required && errors.push('Vui Lòng Nhập E-mail')
            return errors
        },
        phoneErrors() {
            const errors = []
            if (!this.$v.phone.$dirty) return errors
            if (!isStrEmpty(this.$v.phone.$model) && !validatePhoneNumber(this.$v.phone.$model)) {
                errors.push('Số điện thoại không đúng định dạng')
            }
            !this.$v.phone.required && errors.push('Không được để trống')
            return errors
        },
    },
    methods: {
        async fetchdata() {
            try {
                let id = this.$router.history.current.params.id
                const resp = await registerServicesService.detail(id)
                if (resp.code === 200 && !isObjEmpty(resp.data)) {
                    this.email = resp.data.email
                    this.fullName = resp.data.fullName
                    this.phone = resp.data.phone
                    this.isConnected = resp.data.isConnected
                    this.isLoadingScreen = false
                } else {
                    this.$router.push('/cms/register-services')
                }
            } catch (error) {
                console.log(error)
            }
        },

        async submit() {
            try {
                this.loading = true
                this.$v.$touch()
                if (!this.$v.$invalid && validatePhoneNumber(this.phone)) {
                    let id = this.$router.history.current.params.id

                    const { isConnected, fullName, phone, email } = this
                    let param = {
                        isConnected,
                        fullName,
                        phone,
                        email,
                    }
                    const resp = await registerServicesService.update(id, param)
                    if (resp.code === 200) {
                        let message = {
                            text: resp.data.msg,
                            type: 'success',
                        }
                        EventBus.$emit('show-notications', message)
                        this.$router.push('/cms/register-services')
                    } else {
                        let message = {
                            text: resp.data.msg,
                            type: 'error',
                        }
                        EventBus.$emit('show-notications', message)
                        this.loading = false
                    }
                } else {
                    this.loading = false
                }
            } catch (error) {
                this.loading = false
                console.log(error)
            }
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/cms/sevices/detail.scss';
</style>
