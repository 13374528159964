<template>
    <div class="ql__upload">
        <div class="uploader-wrapper">
            <template v-if="!imageFileUrl">
                <i class="fas fa-plus image-dropzone" @click="chooseImage"></i>
                <input type="file" style="display: none" ref="imageInput" accept="image/png,image/jpeg,image/jpg" @change="handleImageChoosen" />
            </template>
            <template v-else>
                <div class="image-dropzone">
                    <img width="320" :src="`${VUE_APP_API_URL}/${imageFileUrl}`" class="uploaded-image" />
                    <div class="box-image-remove"><i class="fa fa-trash" @click="() => confirmRemove()"></i></div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import uploadService from '@/services/upload.service'
import EventBus from '@/utils/EventBus'
const VUE_APP_API_URL = process.env.VUE_APP_API_URL
export default {
    props: {
        image: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            imageFile: null,
            imageFileUrl: this.image,
            VUE_APP_API_URL: VUE_APP_API_URL,
        }
    },
    created() {
        console.log(this.image, 'this.image')
        EventBus.$on('on-remove-image-upload', (param) => this.clearImage(param))
    },
    destroyed() {
        EventBus.$off('on-remove-image-upload')
    },
    methods: {
        chooseImage() {
            this.$refs.imageInput.click()
        },

        async handleImageChoosen(e) {
            const files = e.target.files

            if (files[0] !== undefined) {
                if (files[0].name.lastIndexOf('.') <= 0) {
                    return
                }

                const fr = new FileReader()

                fr.readAsDataURL(files[0])

                fr.addEventListener('load', async () => {
                    try {
                        const formData = new FormData()
                        formData.append('image', files[0])
                        let respImage = await uploadService.uploadImage(formData)

                        if (respImage.code === 200) {
                            let imageFileUrl = `${respImage.data.path}`

                            this.imageFileUrl = imageFileUrl

                            this.$emit('on-get-file-image', imageFileUrl)
                        }
                    } catch (error) {
                        console.log(error)
                    }
                })
            } else {
                this.clearImage()
            }
        },
        confirmRemove() {
            const param = {
                description: 'bạn có chắc chắn muốn xóa hình này không?',
                nameFunction: 'on-remove-image-upload',
            }
            EventBus.$emit('on-show-dialog', param)
        },

        clearImage() {
            this.imageFile = null
            this.imageFileUrl = null
            this.$emit('on-clear-image')
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/common/_upload.scss';
</style>
