import Vue from 'vue'
import VueRouter from 'vue-router'
import authenticateRoutes from '@/router/authenticate-routes'
import authenticatedRoutes from '@/router/authenticated-routes'
import publicRoutes from '@/router/public-routes'

import { getAccessToken, setToken } from '@/services/axios'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        ...publicRoutes,
        ...authenticateRoutes,
        ...authenticatedRoutes,
        {
            path: '/*',
            name: 'NotFound',
            component: () => import(/* webpackChunkName: "portal" */ '@/views/404/Error404.vue'),
        },
    ],
})

router.beforeEach((to, from, next) => {
    const langSearchParams: any = to.query?.lang || null

    const token = getAccessToken()

    if (langSearchParams) {
        localStorage.setItem('locale', langSearchParams)
    }

    window.scrollTo({ top: 0 })

    const hasMeta = (name: string): boolean => to.matched.some((record) => record.meta[name])

    if (hasMeta('authenticated') && token == null) {
        next({ name: 'Cms Login' })
    }

    if (hasMeta('guest') && token != null) {
        next({ name: 'Cms Home' })
    }
    next()
})

export default router
