<template>
    <section class="home__section-item service" v-if="!isLoading && dataSource.length > 0">
        <TitleCommon text="DỊCH VỤ NỔI BẬT" />
        <v-skeleton-loader class="mx-auto" max-width="90%" type="list-item-two-line, image" v-if="isLoading" />
        <div class="home__service-list" v-else>
            <div class="home__service-item" v-for="(v, i) in dataSource" :key="i">
                <div class="home__service-item-content">
                    <h4 class="home__title" @click="onShowDetail(v.slug)">{{ v[`${getTranslatedTextData('title')}`] }}</h4>
                    <p class="home__description" v-html="v[`${getTranslatedTextData('description')}`]"></p>
                    <span class="home__more" @click="onShowDetail(v.slug)">{{ getTranslatedText('xem thêm') }}</span>
                </div>
                <div class="home__service-item-image">
                    <div class="home__service-item-icon">
                        <img :src="`${VUE_APP_API_URL}/${v.icon}`" />
                    </div>
                    <img :src="`${VUE_APP_API_URL}/${v.image}`" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TitleCommon from '@/components/common/title/index.vue'
import languageMixins from '@/mixins/language/language-mixins'
import servicesService from '@/services/service.service'
import { isArrayEmpty } from '@/utils/utils'

const VUE_APP_API_URL = process.env.VUE_APP_API_URL

export default {
    mixins: [languageMixins],
    components: { TitleCommon },
    data: () => ({
        dataSource: [],
        isLoading: true,
        VUE_APP_API_URL: VUE_APP_API_URL,
    }),
    created() {
        this.fetchData()
    },
    methods: {
        async fetchData() {
            try {
                const resp = await servicesService.list('type=true')
                if (resp.code === 200 && !isArrayEmpty(resp.data)) {
                    this.dataSource = resp.data
                }
                this.isLoading = false
            } catch (error) {
                console.log(error)
            }
        },
        onShowDetail(slug) {
            this.$router.push(`/services/${slug}`)
        },
    },
}
</script>
