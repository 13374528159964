<template>
    <div class="screen-contact">
        <TitleCommon text="LIÊN HỆ" />
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.374599940605!2d109.17743412475068!3d13.816530636582163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x316f14c92d585a95%3A0x7e511e72db34a972!2zQ-G6p3UgU-G7kSA4!5e0!3m2!1svi!2s!4v1681988269683!5m2!1svi!2s" width="100%" height="450" style="border: 0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <div class="screen-contact__content">
            <div class="screen-contact__information">
                <h4 class="screen-contact__title">THÔNG TIN VỀ CHÚNG TÔI</h4>
                <p class="screen-contact__information-text">CÔNG TY TNHH LOGISTICS QUỐC TẾ TRƯỜNG THỊNH</p>
                <p class="screen-contact__information-text">Địa chỉ: 226 Phan Bội Châu, Phường Trần Hưng Đạo, Thành Phố Quy Nhơn, Tỉnh Bình Định, Việt Nam</p>
                <p class="screen-contact__information-text">Hotline: +84983383309</p>
                <p class="screen-contact__information-text">Tel: (+84) 256 3892668 / 3892688</p>
                <p class="screen-contact__information-text">Email: info@truongthinhil.com.vn</p>
                <p class="screen-contact__information-text">Website: truongthinhil.com.vn</p>
            </div>
            <div class="screen-contact__form">
                <v-form @submit.prevent="submitForm">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field outlined label="Tên của bạn" v-model="name" required />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field outlined label="Địa chỉ Email" v-model="email" required :rules="emailRules" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field outlined label="Tiêu đề" v-model="subject" required />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea outlined label="Nội dung liên hệ" v-model="message" required />
                        </v-col>
                        <v-col cols="12">
                            <v-btn color="primary" type="submit">Gửi</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </div>
        </div>
    </div>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import TitleCommon from '@/components/common/title/index.vue'

export default {
    mixins: [languageMixins],
    components: { TitleCommon },
    props: {
        text: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            name: '',
            email: '',
            subject: '',
            message: '',
            emailRules: [(v) => !!v || 'Vui lòng nhập địa chỉ email của bạn', (v) => /.+@.+\..+/.test(v) || 'Địa chỉ email không hợp lệ'],
        }
    },
    methods: {
        submitForm() {
            const formData = {
                name: this.name,
                email: this.email,
                subject: this.subject,
                message: this.message,
            }
            console.log(formData)
            // Gửi dữ liệu đến server ở đây
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/contact/_index.scss';
</style>
