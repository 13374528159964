<template>
    <div class="home">
        <v-skeleton-loader class="mx-auto" max-width="90%" type="image" v-if="isLoading" />
        <v-carousel v-else cycle show-arrows-on-hover show-dots :height="550" :show-arrows="false">
            <v-carousel-item v-for="(item, i) in dataSource" :key="i">
                <img :src="`${VUE_APP_API_URL}/${item.image}`" :alt="item.image" width="100%" />
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
import bannerService from '@/services/banner.service'
const VUE_APP_API_URL = process.env.VUE_APP_API_URL

export default {
    data: () => ({
        dataSource: [],
        isLoading: true,
        VUE_APP_API_URL: VUE_APP_API_URL,
    }),
    created() {
        this.fetchdata()
    },
    methods: {
        async fetchdata() {
            try {
                const resp = await bannerService.list()
                if (resp.code === 200) {
                    this.dataSource = resp.data
                }
                this.isLoading = false
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>
