<template>
    <div class="ql__header">
        <div class="ql__header-item">
            <span>LOGISTICS</span>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    data() {
        return {}
    },

    created() {},
    mounted() {},
    methods: {},
}
</script>

<style lang="scss">
@import '~ThemePath/scss/cms/layouts/header.scss';
</style>
